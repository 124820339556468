import HLS from 'hls.js';

function mountVideo() {
  const hls = new HLS();

  const videoEl = document.querySelector('#video');
  if (!videoEl || !videoEl.dataset.playlist) {
    return;
  }
  if (!HLS.isSupported()) {
    return;
  }

  hls.attachMedia(videoEl);
  hls.on(HLS.Events.MEDIA_ATTACHED, () => {
    hls.loadSource(videoEl.dataset.playlist);
  });
}

function unmountVideo() {
  document.removeEventListener('turbo:load', mountVideo);
  document.removeEventListener('turbo:before-unload', unmountVideo);
}

document.addEventListener('turbo:load', mountVideo);
document.addEventListener('turbo:before-unload', unmountVideo);
