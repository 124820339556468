function getSignedUrl(token, file) {
  return new Promise((resolve, reject) => {
    return fetch('/signed_url?type=resource', {
      method: 'POST',
      body: JSON.stringify({
        token,
        filename: file.name,
        mime_type: file.type,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
}

function uploadFile(file) {
  return new Promise((resolve, reject) => {
    const token = document.querySelector('#resource_token').value;

    getSignedUrl(token, file).then(function (json) {
      const data = new FormData();
      Object.keys(json.data).forEach((key) => {
        data.append(key, json.data[key]);
      });

      data.append('file', file);

      fetch(json.url, {
        method: 'POST',
        body: data,
      })
        .then((s3Response) => s3Response.text())
        .then((s3Data) => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(s3Data, 'text/xml');
          const locationTag = xmlDoc.getElementsByTagName('Location')[0];
          resolve(locationTag.innerHTML);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

const handleUploadClicked = () => {
  const button = document.querySelector('.upload-button');

  button.textContent = 'Uploading…';

  const fileEl = document.querySelector('#file');
  const file = fileEl.files[0];

  uploadFile(file).then((url) => {
    button.textContent = 'Upload';

    fileEl.value = null;

    const editor = document.querySelector('.uris-editor').jsoneditor;
    let json = editor.get();

    if (!Array.isArray(json)) {
      json = [];
    }

    json.push({
      type: 'original',
      uri: decodeURIComponent(url),
      size: file.size,
    });

    editor.set(json);

    document.querySelector('#resource_uris').value = JSON.stringify(
      editor.get(),
    );
  });
};

document.addEventListener('turbo:load', function () {
  const isNewResource = document.querySelector('body.resources.new');
  const isEditResource = document.querySelector('body.resources.edit');
  if (!(isNewResource || isEditResource)) {
    return;
  }

  const button = document.querySelector('.upload-button');
  if (button) {
    button.addEventListener('click', handleUploadClicked);
  }
});

document.addEventListener('turbo:before-cache', function () {
  const uploadButton = document.querySelector('.upload-button');
  if (uploadButton) {
    uploadButton.removeEventListener('click', handleUploadClicked);
  }
});
