import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

let dataViewer;

document.addEventListener('turbo:before-cache', function () {
  if (dataViewer) {
    dataViewer.destroy();
  }
});

document.addEventListener('turbo:load', function () {
  if (!document.querySelector('body.diagnostic_dumps.show')) {
    return;
  }

  const dataViewerEl = document.querySelector('.data-viewer');
  dataViewer = new JSONEditor(dataViewerEl, {
    mode: 'code',
  });

  dataViewer.aceEditor.setReadOnly(true);

  const dataJson = dataViewerEl.dataset.json;
  if (dataJson) {
    dataViewer.set(JSON.parse(dataJson));
  }
});
