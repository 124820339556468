document.addEventListener('turbo:load', function () {
  if (!document.querySelector('body.credit_card.new')) {
    return;
  }

  const stripe = Stripe(
    document.querySelector('meta[name="stripe_publishable_key"]').content,
  );
  const elements = stripe.elements();
  const card = elements.create('card', { hidePostalCode: true });
  card.mount('#card');

  function stripeResponseHandler(token) {
    const form = document.getElementById('credit-card-form');
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'token');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);
    form.submit();
  }

  function getAddressLines() {
    return {
      address_line_1: document.getElementById('address_address_line_1').value,
      address_line_2: document.getElementById('address_address_line_2').value,
      city: document.getElementById('address_city').value,
      state: document.getElementById('address_state').value,
      postal_code: document.getElementById('address_postal_code').value,
      country: document.getElementById('address_country').value,
    };
  }

  function createToken() {
    const addressLines = getAddressLines();
    stripe
      .createToken(card, {
        address_line1: addressLines.address_line_1,
        address_line2: addressLines.address_line_2,
        address_city: addressLines.city,
        address_state: addressLines.state,
        address_zip: addressLines.postal_code,
        address_country: addressLines.country,
      })
      .then(function (result) {
        if (result.error) {
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          stripeResponseHandler(result.token);
        }
      });
  }

  const form = document.getElementById('credit-card-form');
  form.addEventListener('submit', function (e) {
    e.preventDefault();
    createToken();
  });
});
