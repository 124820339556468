document.addEventListener('turbo:load', function () {
  const perPageSelector = document.querySelector('#per_page');
  if (!perPageSelector) {
    return;
  }

  perPageSelector.addEventListener('change', function (event) {
    event.target.form.submit();
  });
});
