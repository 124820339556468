import $ from 'jquery';
import select2 from 'select2';
import 'select2/src/scss/core.scss'; // Loading this in manually
import 'trix';
import 'trix/dist/trix.css'; // Loading this in manually
import 'javascripts/stimulus/register-controllers';
import LocalTime from 'local-time';

import 'dash/credit_card';
import 'dash/pagination';
import 'dash/video';
import 'dash/resources';
import 'dash/diagnostic_dumps';

// CSS
import 'cc_toolbox/core/assets/scss/vendor/_reset.scss';
import 'scss/dash/resources.scss';
import 'scss/dash/trix-content.scss';
import 'scss/dash-temp.scss';
import 'scss/application.scss';

LocalTime.start();
select2($);
